window.addEventListener("DOMContentLoaded", (event) => {
    if (isProductPage()) {
        SegmentEvents.trackProductListViewed();
    }

    CustomCode.initCheckoutButtons();
    CustomCode.initLeadForms();
    CustomCode.addSegmentIdentityToUrl();
});

const CustomCode = (function () {

    function initLeadForms() {
        const leadForms = document.querySelectorAll('form[data-name="Flip Group Active Enquiries"], form[data-name="register-interest"]');

        if (leadForms.length === 0) return;

        leadForms.forEach(form => {
            form.addEventListener("submit", () => {
                SegmentEvents.trackLeadSubmitted(form);
            });
        });
    }

    function initCheckoutButtons() {
        const checkoutBtns = document.querySelectorAll('a[href*="/purchase/flip"]');

        if (checkoutBtns.length === 0) return;

        checkoutBtns.forEach(btn => {
            btn.addEventListener("click", function(event) {
                event.preventDefault();
                
                try {
                    SegmentEvents.trackOrderStarted();
                } catch (error) {
                    console.error(error);
                } finally {
                    setTimeout(function () {
                        window.location.href = btn.getAttribute('href');
                    }, 200);
                }
            });
        });
    }

    function addSegmentIdentityToUrl() {
        const { ajsUid, ajsAid } = getSegmentIdentity();
        const links  = document.querySelectorAll('a');

        links.forEach(link => {
            if (link.href.includes('getflip.com.au')) {
                link.href = addParamsToUrl(link.href, { ajs_uid: ajsUid, ajs_aid: ajsAid });
            }
        });
    }

    return {
        initLeadForms,
        initCheckoutButtons,
        addSegmentIdentityToUrl,
    };
})();
