function isProductPage() {
    const pathname = window.location.pathname;
    const pattern = /^\/products\/|\/products-staging\//;
    return pattern.test(pathname);
}

function getFirstLastName(traits, fullName) {
    if (typeof fullName === "string" && fullName != "") {
        const first_name = fullName.split(" ").slice(0, -1).join(" ");
        const last_name = fullName.split(" ").slice(-1).join(" ");

        return { ...traits, first_name, last_name };
    }

    return traits;
}

function standardisedPhoneNumber(phoneNumber) {
    phoneNumber = phoneNumber.replace(/[ ]/g, "").replace("(0)", "");
    phoneNumber = phoneNumber.replace(/^00/, "+");
    phoneNumber = phoneNumber.replace(/[^\d\+]/g, "").trim();

    if (!phoneNumber.startsWith("+")) {
        phoneNumber = "+" + phoneNumber;
    }

    return phoneNumber == "+" ? "" : phoneNumber;
}

function getLocalStorageItem(key) {
    const item = localStorage.getItem(key);
    if (item === null || item === "null" || item === "") {
        return "";
    }

    try {
        return JSON.parse(item);
    } catch (e) {
        return item;
    }
}

function getSegmentIdentity() {
    let ajsUid = getLocalStorageItem("ajs_user_id");
    let ajsAid = getLocalStorageItem("ajs_anonymous_id");

    return { ajsUid, ajsAid };
}

function addParamsToUrl(url, params) {
    const urlObj = new URL(url);
    Object.keys(params).forEach((key) => {
        urlObj.searchParams.set(key, params[key]);
    });

    return urlObj.toString();
}